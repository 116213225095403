<template>
  <div class="d-block w-100">
    <MedicineComponent class/>
  </div>
</template>

<script>
import MedicineComponent from '@/components/common/Medicine/MedicineContainer'
export default {
  component: 'MedicineComponentPage',
  components: {
    MedicineComponent
  }
}
</script>